import React from "react";

const NextIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="44.08"
      height="44.08"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.5"
        cy="23.5"
        r="22"
        transform="rotate(-180 23.5 23.5)"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6749 31.5176C21.3181 32.1608 22.3608 32.1608 23.004 31.5176L29.5176 25.004C29.8468 24.6749 30.0075 24.2411 29.9997 23.8098C30.0059 23.3804 29.8452 22.949 29.5176 22.6213L23.0039 16.1077C22.3607 15.4645 21.318 15.4645 20.6749 16.1077C20.0317 16.7508 20.0317 17.7936 20.6749 18.4367L26.0509 23.8127L20.6749 29.1886C20.0318 29.8318 20.0318 30.8745 20.6749 31.5176Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NextIcon;

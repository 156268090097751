import React, { useState } from "react";
import "./FeedbackModal.css";
import dark_ramka from "../../../assets/dark_ramka.webp";
import CloseIcon from "../icon/CloseIcon";
import { Link } from "react-router-dom";
import { PAGES_URLS } from "../../../config/pages-url.config";
import LoaderCss from "../loader/LoaderCss";
import Notification from "../NotificationPoper/NotificationPoper";
import { useCarouselContext } from "../../../context/CarouselContext";

const FeedbackModal = () => {
  const { setCurrentSlide, setShowText } = useCarouselContext();
  const [formData, setFormData] = useState({
    fullname: "",
    phone_number: "",
    is_anonymous: false,
    description: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendFeedbackLoading, setSendFeedbackLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const onFormChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSendFeedbackLoading(true);

    const { fullname, phone_number, is_anonymous, description } = formData;

    const dataToSubmit = is_anonymous
      ? { description }
      : {
          fullname: fullname.trim() !== "" ? fullname : undefined,
          phone_number: phone_number.trim() !== "" ? phone_number : undefined,
          description,
        };

    Object.keys(dataToSubmit).forEach((key) => {
      if (dataToSubmit[key] === undefined) {
        delete dataToSubmit[key];
      }
    });

    try {
      const url = PAGES_URLS.BASE_URL + PAGES_URLS.FEEDBACK;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSubmit),
      });

      if (!response.ok) {
        setNotification("Ошибка при отправке отзыва");
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      setFormData({
        fullname: "",
        phone_number: "",
        is_anonymous: false,
        description: "",
      });
      setTimeout(() => {
        setSendFeedbackLoading(false);
        setIsSubmitted(true);
      }, 1000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClose = () => {
    setShowText(false);
    setCurrentSlide(0);
    setIsSubmitted(false);
  };

  return (
    <div className="pt-[36px] h-screen pb-[10.91%]">
      {loading && <LoaderCss />}
      <div className=" relative px-[12px] mx-auto">
        <div
          className={`${
            loading ? "hidden" : "block"
          } w-full text-end mb-[14px] pr-[20px]`}
        >
          <Link to={"/"}>
            <button
              className={` text-[white] hover:opacity-85 w-[135px] h-[35px] border-y`}
              onClick={handleClose}
            >
              <span className="flex items-center justify-center gap-[9.86px]">
                <CloseIcon />
                <p className="font-stolzl font-light text-[18px] tracking-[4%]">
                  ЗАКРЫТЬ
                </p>
              </span>
            </button>
          </Link>
        </div>

        <div className=" min-h-[617px] xs:min-h-[500px] mt-[14px] relative flex flex-col justify-center items-center">
          <div>
            <img
              className=""
              loading="eager"
              src={dark_ramka}
              alt="dark_ramka"
              onLoad={() => setLoading(false)}
            />
          </div>
          {sendFeedbackLoading && <LoaderCss />}
          <div className="modal-content absolute z-[1] flex flex-col justify-center w-[60%] text-white">
            {isSubmitted ? (
              <div className="text-center w-full flex justify-center">
                <h1 className="text-[#883C28] w-[125.53px] text-center font-stolzl font-regular text-[23.85px] tracking-[4%] leading-[23.62px]">
                  СПАСИБО ЗА ОТЗЫВ
                </h1>
              </div>
            ) : (
              !sendFeedbackLoading && (
                <div className={`${loading ? "hidden" : "block"}`}>
                  <div className="h-[50px] w-full sm:h-[40px] text-[#883C28] font-stolzl font-regular ">
                    <span className="text-[25.62px] mr-1 tracking-[4%] leading-[25.35px] xs:text-[20px]">
                      ОСТАВИТЬ ОТЗЫВ
                    </span>
                    <span className="text-[12px] xs:text-[10px] whitespace-nowrap">
                      [для руководства]
                    </span>
                  </div>
                  <form
                    className="flex mt-[10%] flex-col gap-[15.38px] xs:gap-[10px] sm:gap-[10px]"
                    onSubmit={handleSubmit}
                  >
                    <input
                      className="w-full placeholder-883C28 h-[37.94px] xs:h-[30px] focus:outline-none bg-inherit text-[#883C28] font-stolzl text-[15.38px] xs:text-[13px] leading-[15.23px] tracking-[4%] py-[11.28px] border-y-[1.03px] border-[#883C28]"
                      type="text"
                      placeholder="Ваше имя"
                      maxLength={35}
                      name="fullname"
                      value={formData.fullname}
                      onChange={onFormChange}
                      spellCheck="false"
                    />
                    <input
                      className="w-full placeholder-883C28 h-[37.94px] xs:h-[30px] focus:outline-none bg-inherit text-[#883C28] font-stolzl text-[15.38px] xs:text-[13px] leading-[15.23px] tracking-[4%] py-[11.28px] border-y-[1.03px] border-[#883C28]"
                      type="tel"
                      maxLength={13}
                      name="phone_number"
                      placeholder="Номер телефона"
                      value={formData.phone_number}
                      onChange={onFormChange}
                      spellCheck="false"
                      pattern="[0-9]*"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                    <textarea
                      className="resize-none w-full h-[178.06px] sm:h-[120px] hsm:h-[145px] p-[10px] border-[1.03px] border-[#883C28] placeholder-883C28 bg-inherit focus:outline-none text-[#883C28] font-stolzl text-[15.38px] xs:text-[13px] leading-[15.23px] tracking-[4%]"
                      placeholder="Отзыв*"
                      name="description"
                      required
                      value={formData.description}
                      onChange={onFormChange}
                      spellCheck="false"
                    ></textarea>
                    <button
                      className="hover:bg-[#883C28] p-[10px] text-[14.31px] xs:p-[8px] xs:text-[12px] leading-[14.17px] bg-[#A9563C] text-black border-0 tracking-[4%] font-stolzl font-regular"
                      type="submit"
                    >
                      ОТПРАВИТЬ
                    </button>
                    <label
                      htmlFor="anonymous-checkbox"
                      className="h-[30px] flex items-center pl-2 w-full"
                    >
                      <input
                        className="custom-checkbox"
                        type="checkbox"
                        id="anonymous-checkbox"
                        checked={formData.is_anonymous}
                        onChange={onFormChange}
                        name="is_anonymous"
                      />
                      <span className="text-[#883C28] font-[500] text-[14px] ml-2">
                        Отправить анонимно
                      </span>
                    </label>
                  </form>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {notification && (
        <Notification
          message={"Ошибка при отправке отзыва"}
          type={"error"}
          onClose={() => setNotification(false)}
        />
      )}

      <div className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.75)] z-[-2]"></div>
    </div>
  );
};

export default FeedbackModal;

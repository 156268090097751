import React from "react";

export default function CloseIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1422 0.707107L13.4351 0L7.07107 6.36398L0.707107 1.60336e-05L0 0.707123L6.36397 7.07109L2.99811e-05 13.435L0.707137 14.1421L7.07107 7.7782L13.435 14.1422L14.1421 13.435L7.77818 7.07109L14.1422 0.707107Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../config/pages-url.config";
import LoaderCss from "../../ui/loader/LoaderCss";
import CarouselSlide from "./CarouselSlide";
import { useCarouselContext } from "../../../context/CarouselContext";
const Carousel = ({ categories = [] }) => {
  const [carouselData, setCarouselData] = useState([]);
  const [error, setError] = useState(null);
  const [loadedData, setLoadedData] = useState(false);

  const {
    currentCategory,
    setCurrentCategory,
    setNextCategory,
    setPrevCategory,
    isLoading,
    setIsLoading,
  } = useCarouselContext();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (categories.length > 0) {
      const urlParams = new URLSearchParams(location.search);
      const category = urlParams.get("category") || categories[0].id;
      const isParamInCategory = categories.some(
        (item) => String(item.id) === String(category)
      );
      if (!isParamInCategory) {
        navigate(`?category=${categories[0].id}`);
      }
      setCurrentCategory(category);
    }
  }, [location.search, categories, setCurrentCategory, navigate]);

  useEffect(() => {
    if (currentCategory && categories.length > 0) {
      const currentIndex = categories.findIndex(
        (item) => String(item.id) === String(currentCategory)
      );

      const nextIndex =
        currentIndex !== -1 && currentIndex < categories.length - 1
          ? currentIndex + 1
          : 0;

      const prevIndex =
        currentIndex > 0 ? currentIndex - 1 : categories.length - 1;

      setNextCategory(categories[nextIndex].id);
      setPrevCategory(categories[prevIndex].id);
    }
  }, [currentCategory, categories, setNextCategory, setPrevCategory]);

  const fetchCarouselData = useCallback(async () => {
    try {
      const url = `${PAGES_URLS.BASE_URL}${PAGES_URLS.ALLDATAES}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCarouselData(data);
      setIsLoading(false);
      setLoadedData(true);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      setLoadedData(true);
    }
  }, [setCarouselData, setIsLoading, setError]);

  useEffect(() => {
    fetchCarouselData();
  }, [fetchCarouselData]);

  return (
    <div className="relative min-h-[65vh] w-full flex flex-col justify-center items-center">
      {isLoading && <LoaderCss />}

      {error && !isLoading && (
        <div className="text-white text-[22px] ">{error}</div>
      )}
      {!isLoading && loadedData && carouselData.length === 0 && !error && (
        <div className="w-full text-center text-white text-[18px] font-adieu font-medium">
          Нет данных :(
        </div>
      )}
      {Array.isArray(carouselData) && carouselData.length > 0 && (
        <CarouselSlide
          currentCategory={currentCategory}
          carouselData={carouselData}
        />
      )}
    </div>
  );
};

export default Carousel;

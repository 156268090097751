import React, { useEffect, useState } from "react";
import InstaIcon from "../ui/icon/InstaIcon";
import { Link } from "react-router-dom";
import { PAGES_URLS } from "../../config/pages-url.config";

export default function FooterCP() {
  const [clicked, setClicked] = useState(false);
  const [instaLink, setInstaLink] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 200);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${PAGES_URLS.BASE_URL}${PAGES_URLS.CONTACTS}`;
        const response = await fetch(url);
        const data = await response.json();
        setInstaLink(data[0]?.instagram_link);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="flex w-full justify-between  items-center">
      <button
        className={`xs:w-[170px] xsi:w-[170px] sm:w-[180px] w-[205px] border border-white h-[35px] ${
          clicked ? "animate-animate-button" : ""
        }`}
        onClick={handleClick}
      >
        <Link className="select-none" to={"/feedback"}>
          <p className="select-none xs:text-[15px] xsi:text-[15px] sm:text-[16px] text-[18px] whitespace-nowrap tracking-[4%] font-stolzl font-regular text-white">
            ОСТАВИТЬ ОТЗЫВ
          </p>
        </Link>
      </button>
      <Link target="_blank" to={instaLink ? instaLink : "#"}>
        <InstaIcon />
      </Link>
    </section>
  );
}

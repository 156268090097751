import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderCP from "../Header/HeaderCP";
import FooterCP from "../Footer/FooterCP";
import greetingIcon from '../../assets/GreetingIcon.webp';
import { PAGES_URLS } from "../../config/pages-url.config";
import Carousel from "./Slider/Carousel";
import audio from "../../assets/audio/audio.mp3";

export default function Content() {
  const [showGreeting, setShowGreeting] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [navs, setNavs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const audioRef = useRef(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const fetchNavs = async () => {
    try {
      const url = `${PAGES_URLS.BASE_URL}${PAGES_URLS.MENU}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setNavs(data);
    } catch (err) {
      setIsError(true);
      setError(err?.message);
    }
  };

  useEffect(() => {
    fetchNavs();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const isFirstTime = urlParams.get("greetings") === "true";

    if (isFirstTime) {
      setShowGreeting(true);
    } else {
      setContentVisible(true);
    }

    if (!urlParams.get("category") && navs.length > 0) {
      urlParams.set("category", navs[0].id);
      navigate({ search: urlParams.toString() }, { replace: true });
    }
  }, [location.search, navs, navigate]);

  useEffect(() => {
    if (showGreeting) {
      const timer = setTimeout(() => {
        setShowGreeting(false);
        setContentVisible(true);
        const urlParams = new URLSearchParams(location.search);
        urlParams.delete("greetings");
        navigate({ search: urlParams.toString() }, { replace: true });
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showGreeting, location.search, navigate]);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (audioRef.current) {
        audioRef.current.play().then(() => {
          setIsAudioPlaying(true);
        }).catch(error => {
          console.error("Failed to play audio:", error);
        });
      }

      document.removeEventListener('click', handleUserInteraction);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (audioRef.current && isAudioPlaying) {
          audioRef.current.pause();
          setIsAudioPlaying(false);
        }
      } else {
        if (audioRef.current && !isAudioPlaying) {
          audioRef.current.play().then(() => {
            setIsAudioPlaying(true);
          }).catch(error => {
            console.error("Failed to play audio:", error);
          });
        }
      }
    };

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAudioPlaying]);
  return (
    <>
      {showGreeting && (
        <div className='w-full h-screen  flex justify-center items-center mt-[-50px] bg-[#242424] opacity-85'>
          <img src={greetingIcon} alt='Greeting Icon' />
        </div>
      )}
      {navs.length > 0 && (
        <div
          className={`${
            contentVisible ? "block" : "hidden"
          } h-screen border-white xs:pt-[25px] xsi:pt-[25px] pt-[30px] hmd:pt-[45px] flex flex-col justify-between hsm:pb-[7%] pb-[16%] sm:pb-[13%] bg-[#242424] bg-opacity-30 `}
        >
          <header className="xs:pl-[20px] xsi:pl-[20px] pl-[37px] sm:pl-[30px]">
            <HeaderCP navs={navs} />
          </header>
          <main className="sm:px-[10px] hmd:px-[15px] px-[25px] sm:mt-0 mt-[10px] sm:mb-0 mb-[10px]">
            <Carousel categories={navs} />
          </main>
          <footer className="xs:px-[20px] xsi:px-[20px] sm:px-[26px] px-[36px]">
            <FooterCP />
          </footer>
        </div>
      )}
        
      {isError && <p className="text-red-500 text-[20px] p-3">Error: {error}</p>}
      {audio && <audio ref={audioRef} src={audio} className="hidden" loop />} 
    </>
  );
}

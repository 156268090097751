import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { getTextClassName, NextArrow, PrevArrow } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useCarouselContext } from "../../../context/CarouselContext";
import "./CarouselSlide.css";
import LoaderCss from "../../ui/loader/LoaderCss";
import ramka from "../../../assets/ramka.png";
import CustomVideoPlayer from "./CustomVideoPlayer";
import "../../../index.css";
import { PAGES_URLS } from "../../../config/pages-url.config";

const CarouselSlide = ({ currentCategory, carouselData = [] }) => {
  const {
    setCurrentSlide,
    currentSlide,
    nextCategory,
    prevCategory,
    showText,
    setShowText,
    videoPreview,
    setVideoPreview,
    onlyImgLoaded,
    setOnlyImgLoaded,
    setShowPlayIcon,
    setVideoLoaded,
    setIsPlaying,
  } = useCarouselContext();
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const [startX, setStartX] = useState(null);
  const [ramkaLoaded, setRamkaLoaded] = useState(false);

  const filteredData = useCallback((data = [], currentCategory) => {
    const category = data.find(
      (item) => String(item.id) === String(currentCategory)
    );
    return category ? category.foods || [] : [];
  }, []);

  const foods = useMemo(
    () => filteredData(carouselData, currentCategory),
    [carouselData, currentCategory, filteredData]
  );

  useEffect(() => {
    setRamkaLoaded(false);
    setVideoPreview(false);
    setVideoLoaded(false);
    setIsPlaying(false);
    setOnlyImgLoaded(false);
    setShowPlayIcon(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(currentSlide, 0);
    }
  }, [currentCategory, currentSlide]);

  useEffect(() => {
    if (onlyImgLoaded || videoPreview) {
      const timeout = setTimeout(() => {
        setShowText(true);
      }, 500);
      return () => clearTimeout(timeout);
    } else {
      setShowText(false);
    }
  }, [onlyImgLoaded, videoPreview, setShowText]);

  const loadedImg = () => {
    setTimeout(() => {
      setOnlyImgLoaded(true);
    }, 0);
  };

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };

  const next = () => {
    const lastSlideIndex = foods.length - 1;
    if (currentSlide === lastSlideIndex && nextCategory) {
      navigate(`?category=${nextCategory}`);
      const timeout = setTimeout(() => {
        setCurrentSlide(0);
        swiperRef.current.swiper.slideTo(0, 0);
        setOnlyImgLoaded(false);
        setShowText(false);
      }, 1);
      return () => clearTimeout(timeout);
    } else {
      swiperRef.current.swiper.slideNext();
      setTimeout(() => {
        setShowPlayIcon(true);
      }, 0);
    }
  };

  const previous = () => {
    if (currentSlide === 0 && prevCategory) {
      const prevFoods = filteredData(carouselData, prevCategory);
      if (prevFoods.length === 0) {
        console.error("Нет данных для предыдущей категории");
        return;
      }
      const lastSlideIndex = prevFoods.length - 1;
      navigate(`?category=${prevCategory}`);

      const timeout = setTimeout(() => {
        setCurrentSlide(lastSlideIndex);
        

        setOnlyImgLoaded(false);
        setShowText(false);
      }, 0);
      return () => clearTimeout(timeout);
    } else if (currentSlide > 0) {
      swiperRef.current.swiper.slidePrev();
      setTimeout(() => {
        setShowPlayIcon(true);
      }, 0);
    }
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0]?.clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0]?.clientX;
    const deltaX = endX - startX;
    const slideWidth = swiperRef.current?.swiper?.slides[0]?.offsetWidth;
    const threshold = slideWidth * 0.2;

    if (deltaX > threshold) {
      if (currentSlide === 0 && prevCategory) {
        previous();
      }
    } else if (deltaX < -threshold) {
      if (currentSlide === foods.length - 1 && nextCategory) {
        next();
      }
    }

    setStartX(null);
  };
  // console.log('foods', foods);
  return (
    <div className="relative  hxsi:max-h-[80vh]">
      <div className="w-full">
        <Swiper
          spaceBetween={30}
          className="mySwiper max-w-[300px] xsi:max-w-[310px] sm:max-w-[335px] md:max-w-[350px] lg:max-w-[380px] "
          ref={swiperRef}
          onSlideChange={handleSlideChange}
        >
          {foods.length &&
            foods.map((food, index) => (
              <SwiperSlide
                className="relative w-full"
                key={`${currentCategory}-${index}`}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                <img
                  src={ramka && ramka}
                  alt="Ramka"
                  className=" h-full w-full pointer-events-none "
                  onLoad={() => setRamkaLoaded(true)}
                />
                {food.image !== null && food.video === null && (
                  <div
                    className={`w-[74%] ${
                      onlyImgLoaded ? "visible" : "hidden"
                    }   top-[10%] absolute ombre z-[-2] left-1/2 transform -translate-x-1/2 flex justify-center items-center`}
                  >
                    <img
                      className={`w-full object-cover ${
                        ramkaLoaded ? "visible" : "hidden"
                      }`}
                      src={PAGES_URLS.BASE_URL + food?.image}
                      alt={food?.name}
                      onLoad={loadedImg}
                    />
                  </div>
                )}
                {food.image !== null &&
                  food.video === null &&
                  !onlyImgLoaded && <LoaderCss />}
                {food?.video !== null && !videoPreview && <LoaderCss />}
                {food?.video !== null && (
                  <CustomVideoPlayer
                    index={index}
                    ramkaLoaded={ramkaLoaded}
                    src={PAGES_URLS.BASE_URL + food?.video}
                    preview={PAGES_URLS.BASE_URL + food?.image}
                  />
                )}

                {showText && ramkaLoaded && currentSlide === index && (
                  <div className="absolute bottom-[16.5%] w-full  xs:max-w-[190px] xsi:max-w-[200px] sm:max-w-[200px] md:max-w-[220px] lg:max-w-[235px] z-[-1] left-1/2 transform -translate-x-1/2 text-white text-center">
                    <p
                      className={`${getTextClassName(
                        food?.name
                      )} mb-[8px] font-adieu font-regular user-select-none`}
                    >
                      {food?.name}
                    </p>
                    <p className="user-select-none text-[14.07px] font-stolzl font-medium leading-[13.93px] tracking-[4%]">
                      {food?.price} сом
                    </p>
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div
        className={`${foods.length > 0 ? "visible" : "hidden"} ${
          ramkaLoaded ? "visible" : "hidden"
        } absolute top-[44.3%] left-[1.15%] xsi:left-[2.5%] sm:left-[2.7%] md:left-[3%] lg:left-[4%] z-20 select-none`}
        onClick={previous}
      >
        <PrevArrow />
      </div>

      <div
        className={`${foods.length > 0 ? "visible" : "hidden"} ${
          ramkaLoaded ? "visible" : "hidden"
        } absolute top-[44.3%] right-[0.7%] xsi:right-[2%] sm:right-[2.2%] md:right-[2.7%] lg:right-[3.7%] z-20`}
        onClick={next}
      >
        <NextArrow />
      </div>
    </div>
  );
};

export default CarouselSlide;

import React from "react";

export default function PlayIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="103"
      height="103"
      viewBox="0 0 103 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="51.1103"
        cy="51.1103"
        r="50.1725"
        stroke="#EEEEEE"
        strokeWidth="1.87561"
      />
      <path
        d="M43.3734 39.334L63.7706 51.1104L43.3734 62.8867L43.3734 39.334Z"
        stroke="#EEEEEE"
        strokeWidth="1.87561"
      />
    </svg>
  );
}

import React, { useEffect, useState } from 'react';
import './Notification.css';

const Notification = ({ message, type, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onClose();
      }, 500); 
    }, 4000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`notification ${type} ${visible ? 'show' : 'hide'}`}>
      {message}
    </div>
  );
};

export default Notification;

import React from 'react';
import bgImg from './assets/herbs.webp'
import { Navigate, Route, Routes } from 'react-router-dom';
import Content from './components/Content/Content';
import FeedbackModal from './components/ui/FeedbackModal/FeedbackModal';
function App() {

  return (
    <div className={`overflow-hidden relative max-w-[430px] my-0  mx-auto `}>
      <Routes>
        <Route path='/' element={<Content />} />
        <Route path='/feedback' element={<FeedbackModal />} />
        <Route path="*" element={<Navigate to={'/'} />} />

      </Routes>
      <div className="absolute top-0 right-0 z-[-5]">
        <img src={bgImg} alt="bgImg" />
      </div>
    </div>
  );
}

export default App;

import React from "react";

const PrevIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="44.08"
      height="44.08"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.5"
        cy="23.5"
        r="22"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3251 15.4824C25.6819 14.8392 24.6392 14.8392 23.996 15.4824L17.4824 21.996C17.1532 22.3251 16.9925 22.7589 17.0003 23.1902C16.9941 23.6196 17.1548 24.051 17.4825 24.3787L23.9961 30.8923C24.6393 31.5355 25.682 31.5355 26.3251 30.8923C26.9683 30.2492 26.9683 29.2064 26.3251 28.5633L20.9491 23.1873L26.3251 17.8114C26.9682 17.1682 26.9682 16.1255 26.3251 15.4824Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PrevIcon;

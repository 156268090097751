import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCarouselContext } from "../../context/CarouselContext";

export default function HeaderCP({ navs = [] }) {
  const { setCurrentSlide } = useCarouselContext();
  const location = useLocation();
  const linkRefs = useRef({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const category = searchParams.get("category");
    const activeLinkRef = linkRefs.current[category];

    if (activeLinkRef) {
      activeLinkRef.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [location.search]);

  return (
    <nav className="sm:pb-[10px] pb-[15px] overflow-x-scroll no-scrollbar">
      <ul className="w-[440px] flex gap-5 font-stolzl font-regular text-white xs:text-[16px] xsi:text-[16px] sm:text-[17px] text-[18px] leading-[17.82px] tracking-[4%] h-[15px]">
        {navs.length > 0 &&
          navs.map((link, index) => (
            <li key={index}>
              <Link
                onClick={() => {
                  setCurrentSlide(0);
                  // setShowText(false);
                  // setOnlyImgLoaded(false);
                }}
                to={`?category=${link.id}`}
                ref={(el) => (linkRefs.current[link.id] = el)}
                className={`whitespace-nowrap uppercase ${
                  new URLSearchParams(location.search).get("category") ===
                  String(link.id)
                    ? "border-b-2"
                    : ""
                }`}
              >
                {link.name}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
}

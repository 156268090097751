import React from "react";
import NextIcon from "./components/ui/icon/carousel/NextIcon";
import PrevIcon from "./components/ui/icon/carousel/PrevIcon";

export const NextArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`transform-gpu duration-50 ease-in-out text-white cursor-pointer active:scale-95`}
    >
      <NextIcon />
    </div>
  );
};

export const PrevArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`transform-gpu duration-50 ease-in-out text-white cursor-pointer active:scale-95`}
    >
      <PrevIcon />
    </div>
  );
};

export const getTextClassName = (text) => {
  switch (true) {
    case text?.length > 20:
      return "text-[13px] leading-[17px] xs:text-[12px] xs:leading-[15px] xsi:text-[12px] xsi:leading-[15px] uppercase tracking-[1%]";
    default:
      return "text-[23.45px] xs:text-[17px] xs:leading-[19.5px] xsi:text-[20px] xsi:leading-[19.5px] leading-[23.21px] tracking-[-5%]";
  }
};
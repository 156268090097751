import React, { useState, useEffect, useRef } from "react";
import PlayIcon from "../../ui/icon/carousel/PlayIcon";
import "./CustomVideoPlayer.css";
import { useCarouselContext } from "../../../context/CarouselContext";
import LoaderCss from "../../ui/loader/LoaderCss";

const CustomVideoPlayer = ({
  src: videoSource,
  preview,
  ramkaLoaded,
  index: itemIndex,
}) => {
  const {
    setShowPreview,
    showPreview,
    setShowText,
    currentSlide,
    videoLoaded,
    setVideoLoaded,
    currentCategory,
    setVideoPreview,
    videoPreview,
    showPlayIcon,
    setShowPlayIcon,
    isPlaying,
    setIsPlaying,
  } = useCarouselContext();

  const [videoLoader, setVideoLoader] = useState(false);
  const [wasPlayIconClicked, setWasPlayIconClicked] = useState(false);
  const [metadataLoad, setMetadataLoad] = useState();
  const videoRef = useRef(null);
  const abortControllerRef = useRef(null);

  const cancelPreviousRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  const startVideo = () => {
    setShowText(false);
    setShowPlayIcon(false);
    videoRef.current.play().catch((error) => {
      console.error("Error playing video:", error);
    });
    setIsPlaying(true);
  };

  const toggleVideo = () => {
    setTimeout(() => {
      if (videoPreview) {
        setShowPlayIcon(true);
      }
    }, 25);
    videoRef.current.pause();
    setIsPlaying(false);
    setShowPreview(false);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.currentTime = 0;
      toggleVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleVolumeChange = () => {
      videoElement.muted = true;
    };

    videoElement.addEventListener("volumechange", handleVolumeChange);

    return () => {
      videoElement.removeEventListener("volumechange", handleVolumeChange);
    };
  }, [videoRef]);

  const stopVideo = () => {
    setShowText(true);
    videoRef.current.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      stopVideo();
      setWasPlayIconClicked(false);
    }
    cancelPreviousRequest();
    setVideoLoader(false);
    setTimeout(() => {
      setShowPreview(true);
    }, 25);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, currentSlide]);

  const handleVideoClick = () => {
    if (!showPreview) {
      stopVideo();
      setShowPlayIcon(true);
      setShowPreview(false);
    }
  };

  const handleIconClick = () => {
    if (!wasPlayIconClicked) {
      setVideoLoader(true);
      setWasPlayIconClicked(true);
    }
    if (videoLoaded) {
      if (metadataLoad) {
        startVideo();
        setShowPreview(false);
      }
    }
  };

  useEffect(() => {
    if (wasPlayIconClicked && videoLoaded) {
      videoRef.current.currentTime = 0;
      startVideo();
      setShowPreview(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasPlayIconClicked, videoLoaded]);

  const previewVideoLoaded = () => {
    setVideoPreview(true);
  };
 
  useEffect(() => {
    if (videoPreview) {
      const timeout = setTimeout(() => {
        setShowPlayIcon(true);
      }, 500);
      return () => clearTimeout(timeout);
    } else {
      setShowPlayIcon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPreview]);

  return (
    <div
      className={`${
        ramkaLoaded ? "visible" : "hidden"
      } absolute top-[10%] w-[74%] hf:max-h-[80vh] left-1/2 transform -translate-x-1/2 z-[-1] `}
    >
      <div
        className={`h-full ${videoPreview && "ombre"} ${
          !showPreview && "opacity-0"
        } `}
      >
        <img
          className={`w-full object-cover`}
          src={preview}
          alt="Video thumbnail"
          onLoad={previewVideoLoaded}
        />
      </div>

      {videoLoader && <LoaderCss />}

      <video
        ref={videoRef}
        className={`w-full h-full absolute top-0 z-10 object-cover ${
          showPreview && "opacity-0"
        }`}
        loop
        onClick={handleVideoClick}
        onCanPlayThrough={() => {
          setVideoLoaded(true);
        }}
        onWaiting={() => {
          setVideoLoader(true);
        }}
        onPlaying={() => {
          setVideoLoader(false);
        }}
        onLoadedMetadata={() => setMetadataLoad(true)}
        autoPlay={true}
        muted
        playsInline
        preload="auto"
        src={
          videoSource &&
          (currentSlide === itemIndex && videoPreview ? videoSource : "")
        }
        controls={false}
      >
        Your browser does not support the video tag.
      </video>

      {showPlayIcon && (
        <div
          onClick={handleIconClick}
          className={`${
            !videoPreview && "opacity-0"
          } absolute top-[48%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[40] transform-gpu duration-50 ease-in-out text-white cursor-pointer active:scale-95`}
        >
          <PlayIcon />
        </div>
      )}
    </div>
  );
};

export default CustomVideoPlayer;

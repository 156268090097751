import React, { createContext, useContext, useState } from 'react';

const CarouselContext = createContext();

export const CarouselProvider = ({ children }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [nextCategory, setNextCategory] = useState(null);
  const [prevCategory, setPrevCategory] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showPreview, setShowPreview] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showText, setShowText] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);
  const [onlyImgLoaded, setOnlyImgLoaded] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <CarouselContext.Provider
      value={{
        currentCategory,
        setCurrentCategory,
        nextCategory,
        setNextCategory,
        prevCategory,
        setPrevCategory,
        currentSlide,
        setCurrentSlide,
        showPreview,
        setShowPreview,
        isLoading,
        setIsLoading,
        showText,
        setShowText,
        videoLoaded,
        setVideoLoaded,
        videoPreview,
        setVideoPreview,
        onlyImgLoaded,
        setOnlyImgLoaded,
        showPlayIcon,
        setShowPlayIcon,
        isPlaying,
        setIsPlaying,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

export const useCarouselContext = () => useContext(CarouselContext);
